import { IProfile, IUpdateProfile } from '../../models/profile';
import { instance } from '../index';
import { ICreateGroupBody } from './profile-group.api';
import { AxiosError, AxiosResponse } from 'axios';
import { IPagination, IResponseWithStatus } from '../../models/api';

const serviceEndpoint = 'client/api/Profile';

interface IGetProfileByPhoneParams {
  phone: string;
  type?: string;
  onlyOne?: boolean;
}

interface IGetProfileByPhoneResponse extends IPagination {
  value: IProfile[];
}

interface ICreateEmptyUserResponse extends IResponseWithStatus {
  value: string;
}

export const getCurrentProfile = async (): Promise<IProfile> => {
  const response = await instance.get(`${serviceEndpoint}`);

  return response.data;
};

export const updateCurrentProfile = async (
  profileData: IUpdateProfile,
): Promise<IProfile> => {
  try {
    const response = await instance.put(`${serviceEndpoint}`, profileData);

    return response.data;
  } catch (error) {
    throw (error as AxiosError)?.response?.data;
  }
};

export const getProfileByPhone = async (
  params: IGetProfileByPhoneParams,
): Promise<IProfile | null> => {
  const searchParams = new URLSearchParams(Object.entries(params));

  const response: AxiosResponse<IGetProfileByPhoneResponse> =
    await instance.get(`${serviceEndpoint}/byPhone?${searchParams}`);

  return response.data.value[0] || null;
};

export const createEmptyUser = async (
  phoneNumber: string,
): Promise<ICreateEmptyUserResponse> => {
  const response = await instance.post(
    `${serviceEndpoint}/createEmptyUser?phone=${phoneNumber}`,
  );

  return response.data;
};

//handle groups
export const updateProfileGroup = async (
  group: ICreateGroupBody,
): Promise<IProfile> => {
  const response = await instance.put(`${serviceEndpoint}`, group);
  return response.data;
};

export const deleteProfileGroup = async (id: string): Promise<void> => {
  await instance.delete(`${serviceEndpoint}?profileId=${id}`);
};

export const depersonalizeProfile = async (): Promise<void> => {
  try {
    await instance.get(`${serviceEndpoint}/depersonalize`);
  } catch (error) {
    throw (error as AxiosError)?.response?.data;
  }
};

export const updateOnline = async (): Promise<IResponseWithStatus> => {
  const response = await instance.post(`${serviceEndpoint}/updateOnline`);
  return response.data;
};
