import { atom } from 'recoil';
import {
  IClinic,
  IClinicCard,
  IPriceGroup,
  IReferralTemplate,
} from '../../models/clinic';
import { IReferral } from '../../models/referrals';
import {
  IConsultation,
  IOfflineConsultation,
} from '../../models/consultations';
import { ISpecialist } from '../../models/applications';

export const currentProfileTemplatesState = atom({
  key: 'currentProfileTemplates',
  default: [] as IReferralTemplate[],
});

export const referralDetailsState = atom({
  key: 'referralDetails',
  default: null as IReferral | null,
});

export const consultationDetailsState = atom({
  key: 'consultationDetails',
  default: null as IConsultation | null,
});

export const offlineConsultationDetailsState = atom({
  key: 'offlineConsultationDetails',
  default: null as IOfflineConsultation | null,
});

export const priceGroupsState = atom({
  key: 'priceGroups',
  default: {} as { [key: string]: IPriceGroup[] },
});

export const clinicDetailsState = atom({
  key: 'clinicDetails',
  default: null as IClinicCard | null,
});

export const specialistDetailsState = atom({
  key: 'specialistDetails',
  default: null as ISpecialist | null,
});

export const cachedClinicsState = atom({
  key: 'cachedClinics',
  default: [] as IClinic[],
});

export const calendarState = atom({
  key: 'calendarState',
  default: {
    daysOnPage: 20,
    maxDayCount: 60,
  } as {
    daysOnPage: number;
    maxDayCount: number;
  },
});
