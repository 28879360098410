import React, { FC, HTMLAttributeAnchorTarget } from 'react';
import './button.scss';
import classNames from 'classnames';
import TextButton from './textButton';

interface IButtonComponent {
  text: string;
  action?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'submit' | 'reset' | 'button';
  size?: 'small' | 'medium' | 'large';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  loading?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  variant?: 'contained' | 'outlined';
  color?: 'primary' | 'secondary';
  href?: string;
  clx?: string;
  target?: HTMLAttributeAnchorTarget;
  rel?: string;
  style?: React.CSSProperties;
  id?: string;
}

const Button: FC<IButtonComponent> = ({
  text,
  action,
  type = 'button',
  size = 'medium',
  variant = 'contained',
  color = 'primary',
  startIcon,
  endIcon,
  loading = false,
  fullWidth = false,
  disabled = false,
  href,
  target,
  rel,
  clx,
  id,
}) => {
  const className = classNames(
    'button',
    `button__${color}`,
    `button__${size}`,
    `button__${variant}-${color}`,
    {
      button__disabled: disabled,
      button__active: !disabled,
      button__loading: loading,
      [`button__notLoading_${variant}-${color}`]: !loading,
      button__fullWidth: fullWidth,
    },
    clx,
  );

  const childrenNode = (
    <>
      {startIcon && startIcon}
      {text && <span className="button__text">{text}</span>}
      {endIcon && endIcon}
    </>
  );

  if (href) {
    return (
      <a
        id={id}
        type={type}
        href={href}
        rel={rel || ''}
        target={target || ''}
        className={className}
      >
        {childrenNode}
      </a>
    );
  }

  return (
    <button
      id={id}
      type={type}
      className={className}
      disabled={loading ? true : disabled}
      onClick={event => (!loading && action ? action(event) : null)}
    >
      {childrenNode}
    </button>
  );
};

export default Button;
