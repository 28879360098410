import React, { lazy } from 'react';
import Loadable from '../../components/Loadable';
import { Outlet } from 'react-router-dom';

const Header = Loadable(lazy(() => import('../Header')));

const HeaderLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

export default HeaderLayout;
