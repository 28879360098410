'use client';

import { sendDevice } from 'api/client/device.api';
import { subscribeTopics } from 'api/client/profile.api';
import { onMessage, Unsubscribe } from 'firebase/messaging';
import { LS_FIREBASE_TOKEN } from 'helpers/constants';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { toast } from 'sonner';
import { alertMessageModal } from 'store/modals/modals.states';
import { currentProfileState } from 'store/profiles/profiles.states';
import { fetchToken, messaging } from '../firebase';

async function getNotificationPermissionAndToken() {
  // Step 1: Check if Notifications are supported in the browser.
  if (!('Notification' in window)) {
    console.info('This browser does not support desktop notification');
    return null;
  }

  // Step 2: Check if permission is already granted.
  if (Notification.permission === 'granted') {
    return await fetchToken();
  }

  // Step 3: If permission is not denied, request permission from the user.
  if (Notification.permission !== 'denied') {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      return await fetchToken();
    }
  }

  console.info('Notification permission not granted.');
  return null;
}

const useFcmToken = () => {
  const navigate = useNavigate();
  const [notificationPermissionStatus, setNotificationPermissionStatus] =
    useState<NotificationPermission | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const retryLoadToken = useRef(0);
  const isLoading = useRef(false);
  const currentProfile = useRecoilValue(currentProfileState);
  const [alertModal, setAlertModal] = useRecoilState(alertMessageModal);

  const loadToken = async () => {
    // Step 4: Prevent multiple fetches if already fetched or in progress.
    if (isLoading.current) return;

    isLoading.current = true;
    const token = await getNotificationPermissionAndToken();

    if (token && currentProfile) {
      try {
        await sendDevice({
          userId: currentProfile?.userId,
          deviceToken: token,
          operatingSystem: 'Web',
          appVersion: '1.0',
          phoneVersion: '1.0',
          deviceId: crypto.randomUUID(),
          lat: '0',
          lon: '0',
        });
        await subscribeTopics(token);
        localStorage.setItem(LS_FIREBASE_TOKEN, token);
      } catch (error) {}
    }

    // Step 5: Handle the case where permission is denied.
    if (Notification.permission === 'denied') {
      setNotificationPermissionStatus('denied');
      console.info(
        '%cPush Notifications issue - permission denied',
        'color: green; background: #c7c7c7; padding: 8px; font-size: 20px',
      );
      setAlertModal({
        title: 'У вас отключен доступ к уведомлениям',
        type: 'error',
      });
      isLoading.current = false;
      return;
    }

    // Step 6: Retry fetching the token if necessary. (up to 3 times)
    // This step is typical initially as the service worker may not be ready/installed yet.
    if (!token) {
      if (retryLoadToken.current >= 3) {
        console.info(
          '%cPush Notifications issue - unable to load token after 3 retries',
          'color: green; background: #c7c7c7; padding: 8px; font-size: 20px',
        );
        setAlertModal({
          title:
            'Не удалось подключить уведомления. Попробуйте перезагрузить страницу.',
          type: 'error',
        });
        isLoading.current = false;
        return;
      }

      retryLoadToken.current += 1;
      console.error('An error occurred while retrieving token. Retrying...');
      isLoading.current = false;
      await loadToken();
      return;
    }

    // Step 7: Set the fetched token and mark as fetched.
    setNotificationPermissionStatus(Notification.permission);
    setToken(token);
    isLoading.current = false;
  };

  useEffect(() => {
    // Step 8: Initialize token loading when the component mounts.
    if ('Notification' in window && currentProfile) {
      loadToken();
    }
  }, [currentProfile]);

  useEffect(() => {
    const setupListener = async () => {
      if (!token) return; // Exit if no token is available.

      const m = await messaging();
      if (!m) return;

      // Step 9: Register a listener for incoming FCM messages.
      const unsubscribe = onMessage(m, payload => {
        if (Notification.permission !== 'granted') return;

        const link = payload.data?.id
          ? `/appointments?id=${payload.data?.id}`
          : '/appointments';

        toast.info(`${payload.notification?.body}`, {
          action: {
            label: '',
            onClick: () => {
              if (link) {
                navigate(link);
              }
            },
          },
        });

        // --------------------------------------------
        // Disable this if you only want toast notifications.
        const n = new Notification(payload.notification?.title || '', {
          body: payload.notification?.body || '',
          data: link ? { url: link } : undefined,
        });

        // Step 10: Handle notification click event to navigate to a link if present.
        n.onclick = event => {
          event.preventDefault();
          if (link) {
            navigate(link);
          } else {
            console.info('No link found in the notification payload');
          }
        };
        // --------------------------------------------
      });

      return unsubscribe;
    };

    let unsubscribe: Unsubscribe | null = null;

    if (currentProfile) {
      setupListener().then(unsub => {
        if (unsub) {
          unsubscribe = unsub;
        }
      });
    }

    // Step 11: Cleanup the listener when the component unmounts.
    return () => unsubscribe?.();
  }, [token, toast, currentProfile]);
};

export default useFcmToken;
