import { EnumConsultationStatuses } from 'models/consultations';
import { serviceTypes } from '../models/clinic';
import { ILanguage } from '../models/settings';

//auth
export const connectTokenConstants = {
  client_id: 'mobile-client',
  client_secret: 'de4c3427-9c00-4d28-aa1b-a5ac1d8bd3f2',
  grant_type: 'verification_token',
};
export const partnerConnectTokenConstants = {
  client_id: 'partner-admin-client',
  client_secret: '26f9762d-7a4e-4a39-86f4-37b08e288ceb',
  grant_type: 'password',
};

//regexes
//@ts-ignore
export const Regex_OnlyLettersAndSpaces = /^[\p{L}\-' ]+$/u;

//local storage keys
export const LS_CREDENTIALS_KEY = 'lck';
export const LS_SESSION_ROLE_KEY = 'sessionRole';
export const LOCAL_CURRENCY = 'localCurrency';
export const LS_USER_ADDRESS_KEY = 'luak';

//others
export const mediaQueriesConstants = {
  container: '(min-width: 1500px)',
  minDesktop: '(min-width: 1261px)',
  minLargeTablet: '(min-width: 997px)',
  min900: '(min-width: 900px)',
  minMediumTablet: '(min-width: 769px)',
  minSmallTablet: '(min-width: 577px)',
  mobile: '(max-width: 576px)',
};
export const languages: ILanguage[] = [
  { title: 'RU', value: 'ru' },
  { title: 'KZ', value: 'kz' },
  { title: 'EN', value: 'en' },
  { title: 'KG', value: 'kg' },
];
export const defaultServiceTypes: serviceTypes[] = ['research', 'package'];

//qomek constants
export const qomekSocialMedia = {
  instagram: 'https://instagram.com/qomek.app?igshid=YmMyMTA2M2Y=',
  email: 'office@qomek.net',
  telegram: 'https://t.me/QomekApp',
  whatsapp: 'https://api.whatsapp.com/send?phone=77013487431',
};
export const downloadAppLinks = {
  playMarket: 'https://play.google.com/store/apps/details?id=kz.imed',
  appStore: 'https://apps.apple.com/ru/app/qomek/id1606324169',
};

//others
export type mimeFilesTypes =
  | 'image/png'
  | 'image/jpeg'
  | 'image/jpg'
  | '.doc'
  | '.docx'
  | 'application/pdf';

export const fontSizes = [
  '2xs',
  '1xs',
  'xs',
  'base',
  'lg',
  'xl',
  '1xl',
  '2xl',
  '3xl',
  '4xl',
] as const;

export const consultationStatuses = [
  { name: 'statuses.registered', value: EnumConsultationStatuses.registered },
  {
    name: 'statuses.unproccessed',
    value: EnumConsultationStatuses.unproccessed,
  },
  { name: 'statuses.accepted', value: EnumConsultationStatuses.accepted },
  { name: 'statuses.completed', value: EnumConsultationStatuses.completed },
  { name: 'statuses.cancelled', value: EnumConsultationStatuses.cancelled },
  { name: 'statuses.archived', value: EnumConsultationStatuses.archived },
];
