import { IPagination } from './api';
import { IProfileApplicationExtended } from './applications';
import { IClinic, IPrice, ISymptom } from './clinic';
import { IGroupOnwer, IProfile } from './profile';
import { IReferral } from './referrals';

export interface IConsultationsWithPagination extends IPagination {
  results: IConsultation[];
}

export interface IOfflineConsultationsWithPagination extends IPagination {
  results: IOfflineConsultation[];
}

export interface IConsultation {
  id: string;
  regionCode: string;
  orderId: string | null;
  doctorApplication: IProfileApplicationExtended;
  referral: IReferral | null;
  patient: IProfile;
  deviceId: string;
  patientDescription: string;
  doctorDescription: string | any;
  status: EnumConsultationStatuses;
  registered: string;
  session: any;
  symptoms: ISymptom[];
}

export interface IOfflineConsultation {
  created: string;
  updated: string;
  id: string;
  clinic: IClinic;
  meetDateTime: string;
  doctor: IProfile | null;
  patient: IProfile | null;
  status: EnumConsultationStatuses;
  consultationCode: string;
  comment: string;
  servicesView: IPrice[];
  total: number;
  groupOwner?: IGroupOnwer;
  isPay: boolean;
}

export interface IRegisteredOfflineConsultation {
  id: string;
  doctorProfileId: string;
  selectedServices: string[];
  clinicId: string;
  patientUserId: string;
  meetDateTime: string;
  status: EnumConsultationStatuses;
  created: string;
  consultationCode: string;
  isForAnotherPatient: boolean;
  patientId: string | null;
  comment: string;
}

export enum EnumConsultationStatuses {
  registered = 0,
  unproccessed = 2,
  accepted = 3,
  completed = 6,
  cancelled = 7,
  archived = 8,
}
