import { useRecoilState, useSetRecoilState } from 'recoil';
import { credentialsState } from '../store/auth/auth.states';
import {
  LOCAL_CURRENCY,
  LS_CREDENTIALS_KEY,
  LS_USER_ADDRESS_KEY,
  mediaQueriesConstants,
} from '../helpers/constants';
import {
  currentProfileAvatarState,
  currentProfileGroupState,
  currentProfileScopesState,
  currentProfileState,
} from '../store/profiles/profiles.states';

import { currentProfileDocumentsState } from '../store/files/files.states';
import {
  clinicDetailsState,
  currentProfileTemplatesState,
} from '../store/clinic/clinic.states';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from './useMediaQuery';
import { useQueryClient } from 'react-query';

const useLogout = (): (() => void) => {
  const isLargeTablet = useMediaQuery(mediaQueriesConstants.minLargeTablet);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [currentProfile, setCurrentProfile] =
    useRecoilState(currentProfileState);

  const setCredentials = useSetRecoilState(credentialsState);
  const setProfileGroup = useSetRecoilState(currentProfileGroupState);
  const setCurrentProfileAvatar = useSetRecoilState(currentProfileAvatarState);
  const setClinic = useSetRecoilState(clinicDetailsState);
  const setCurrentProfileDocuments = useSetRecoilState(
    currentProfileDocumentsState,
  );
  const setCurrentProfileTemplates = useSetRecoilState(
    currentProfileTemplatesState,
  );

  const setCurrentProfileScopes = useSetRecoilState(currentProfileScopesState);

  return () => {
    if (!isLargeTablet) navigate('/');

    setClinic(null);
    setCredentials(null);
    setCurrentProfile(null);
    setCurrentProfileAvatar('');
    setProfileGroup([]);
    setCurrentProfileDocuments([]);
    setCurrentProfileTemplates([]);
    setCurrentProfileScopes([]);

    localStorage.removeItem(LS_CREDENTIALS_KEY);
    localStorage.removeItem(LOCAL_CURRENCY);
    localStorage.removeItem(LS_USER_ADDRESS_KEY);
    queryClient.invalidateQueries();
    queryClient.clear();
  };
};

export default useLogout;
