import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//policy docs locales
import kzPolicy from './data/kz/kz.policy.json';
import enPolicy from './data/en/en.policy.json';
import ruPolicy from './data/ru/ru.policy.json';
import kgPolicy from './data/kg/kg.policy.json';
//actions locales
import ruActions from './data/ru/ru.actions.json';
import kgActions from './data/kg/kg.actions.json';
import enActions from './data/en/en.actions.json';
//main locales
import ruMain from './data/ru/ru.main.json';
import kgMain from './data/kg/kg.main.json';
import enMain from './data/en/en.main.json';
//validation locales
import ruMessages from './data/ru/ru.validation.json';
import kgMessages from './data/kg/kg.validation.json';
import enMessages from './data/en/en.validation.json';

i18n.use(initReactI18next).init({
  resources: {
    ru: {
      translation: {
        policy: ruPolicy,
        actions: ruActions,
        validation: ruMessages,
        ...ruMain,
      },
    },
    kg: {
      translation: {
        policy: kgPolicy,
        actions: kgActions,
        validation: kgMessages,
        ...kgMain,
      },
    },
    en: {
      translation: {
        policy: enPolicy,
        actions: enActions,
        validation: enMessages,
        ...enMain,
      },
    },
    kz: {
      translation: {
        policy: kzPolicy,
      },
    },
  },
  lng: localStorage.getItem('language') || 'ru',
  fallbackLng: 'ru',
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ['localStorage', 'cookie'],
    caches: ['localStorage', 'cookie'],
  },
});
