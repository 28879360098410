import axios from 'axios';
import { BASE_URL, IDENTITY_URL } from '../helpers/enviroment';
import {
  getCurrentLanguage,
  getLocalStorageCredentials,
  getLocalStorageUserAddress,
} from '../helpers/utils';

export const apiVersion2Header = { 'api-version': '2.0' };
export const apiVersion3Header = { 'api-version': '3.0' };

export const instanceLogin = axios.create({
  baseURL: IDENTITY_URL,
});

export const instance = axios.create({
  baseURL: BASE_URL,
});

export const instanceUnauthorized = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.request.use(config => {
  const credentials = getLocalStorageCredentials();
  const countryCode = getLocalStorageUserAddress()?.countryCode;

  if (credentials)
    config.headers.set(
      'authorization',
      `${credentials.token_type} ${credentials.access_token}`,
    );
  config.headers.set('country', '');
  config.headers.set('language', getCurrentLanguage());

  return config;
});
