import { instance, instanceUnauthorized } from '../index';
import { IPagination, IResponseWithStatus, sortTypes } from '../../models/api';
import {
  FileTypes,
  IFileData,
  IFileDataWithBlob,
  StorageTypes,
} from '../../models/files';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';

const serviceEndpoint = 'fileapi/api/FileApi';

interface IGetFilesParams {
  FileType?: FileTypes;
  SortType?: sortTypes;
  SortBy?: string;
  UserId?: string;
  Page?: string;
  PageSize?: string;
}

interface IGetFilesByFilersData extends IPagination {
  results: IFileData[];
}

interface IGetFilesByFilersRoot extends IResponseWithStatus {
  value: IGetFilesByFilersData;
}

interface IUploadFileParams {
  fileType?: FileTypes;
  storageType?: StorageTypes;
  subDir?: string;
  description?: string;
}

interface IUploadFileResponse extends IResponseWithStatus {
  value: IFileData;
}

export const getFileById = async (
  fileId: string,
  axiosTokenSource?: CancelTokenSource,
): Promise<string> => {
  const configs: AxiosRequestConfig = {
    responseType: 'blob',
  };

  if (axiosTokenSource) {
    configs.cancelToken = axiosTokenSource.token;
  }

  const response = await instance.get(
    `${serviceEndpoint}?id=${fileId}`,
    configs,
  );
  return URL.createObjectURL(response.data);
};

export const getFileWithoutBearer = async (
  fileId: string,
  cancelTime?: number,
): Promise<string> => {
  let source: CancelTokenSource | null = null;
  let timeoutId;
  if (cancelTime) {
    source = axios.CancelToken.source();
  }
  if (source) {
    timeoutId = setTimeout(() => {
      source!.cancel('Request took too long');
    }, cancelTime);
  }

  const response = await instanceUnauthorized.get(
    `${serviceEndpoint}/getIcons?id=${fileId}`,
    {
      responseType: 'blob',
      cancelToken: source?.token,
    },
  );

  clearTimeout(timeoutId);
  return URL.createObjectURL(response.data);
};

export const getFilesByFilters = async (
  params: IGetFilesParams,
): Promise<IGetFilesByFilersRoot> => {
  const searchParams = new URLSearchParams(Object.entries(params));

  const response = await instance.get(
    `${serviceEndpoint}/pagin?${searchParams}`,
  );
  return response.data;
};

export const getFilesWithBlobByFilters = async (
  params: IGetFilesParams,
): Promise<IFileDataWithBlob[]> => {
  const filesData = await getFilesByFilters(params);
  const withBlobs = await Promise.all(
    filesData.value.results.map(async result => {
      let file: string = '';
      if (result.id) {
        try {
          file = await getFileById(result.id);
        } catch (e) {
          file = '';
        }
      }
      return { ...result, file };
    }),
  );

  return withBlobs.filter(el => el.file);
};

export const uploadFile = async (
  file: FormData,
  params: IUploadFileParams,
): Promise<IUploadFileResponse> => {
  const searchParams = new URLSearchParams(Object.entries(params));

  const response = await instance.post(
    `${serviceEndpoint}?${searchParams}`,
    file,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
  return response.data;
};

export const deleteFileById = async (fileId: string): Promise<void> => {
  await instance.delete(`${serviceEndpoint}/delete?id=${fileId}`);
};

export const getImageFromFileApi = async (
  path: string,
  axiosTokenSource?: CancelTokenSource,
) => {
  let imagePath: string;

  try {
    imagePath = await getFileById(path, axiosTokenSource);
  } catch {
    imagePath = '';
  }

  return imagePath;
};
