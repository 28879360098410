import React, { FC } from 'react';
import './loader.scss';

interface ILoader {
  window: 'full' | 'visible-header' | false;
  size: 'super-small' | 'small' | 'medium' | 'large';
  zIndex?: number;
}

const Loader: FC<ILoader> = ({ window, size, zIndex }) => {
  return window === 'full' ? (
    <div
      style={zIndex ? { zIndex } : {}}
      className="loader__full loader__full_window z-30"
    >
      <div className={`loader loader__${size}`} />
    </div>
  ) : window === 'visible-header' ? (
    <div
      style={zIndex ? { zIndex } : {}}
      className="loader__full loader__full_visibleHeader"
    >
      <div className={`loader loader__${size}`} />
    </div>
  ) : (
    <div
      style={zIndex ? { zIndex } : {}}
      className={`loader loader__${size}`}
    />
  );
};

export default Loader;
