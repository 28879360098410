// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScrollToTopButton_button__uKxJE {\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  background-color: var(--primary-color);\n  border: none;\n  border-radius: 50%;\n  padding: 20px;\n  cursor: pointer;\n  transition: opacity 0.3s ease-in-out;\n  opacity: 0; }\n\n.ScrollToTopButton_button__visible__77\\+tz {\n  opacity: 1; }\n", "",{"version":3,"sources":["webpack://./src/components/ScrollToTopButton/ScrollToTopButton.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,sCAAsC;EACtC,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,oCAAoC;EACpC,UAAU,EAAA;;AAGZ;EACE,UAAU,EAAA","sourcesContent":[".button {\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  background-color: var(--primary-color);\n  border: none;\n  border-radius: 50%;\n  padding: 20px;\n  cursor: pointer;\n  transition: opacity 0.3s ease-in-out;\n  opacity: 0;\n}\n\n.button__visible {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "ScrollToTopButton_button__uKxJE",
	"button__visible": "ScrollToTopButton_button__visible__77+tz"
};
export default ___CSS_LOADER_EXPORT___;
