import { useRoutes } from 'react-router-dom';
import routes from './routes';
import { createPortal } from 'react-dom';
import ScrollToTopButton from 'components/ScrollToTopButton';
import {
  AlertModal,
  AnalysesModal,
  Auth,
  ImageViewerModal,
  InternetConnectionModal,
  LogoutModal,
  SoonModal,
} from 'components/Modals';
import './theme.scss';
import './index.scss';

function App() {
  const content = useRoutes(routes);

  return (
    <>
      {content}

      {createPortal(
        <>
          <Auth />
          <SoonModal />
          <AlertModal />
          <ImageViewerModal />
          <LogoutModal />
          <AnalysesModal />
          <InternetConnectionModal />
          <ScrollToTopButton />
        </>,
        document.getElementById('modal-root')!,
      )}
    </>
  );
}

export default App;
