import { Navigate, RouteObject } from 'react-router-dom';
import Loadable from './components/Loadable';
import { lazy } from 'react';
import HeaderLayout from './shared/Layouts/HeaderLayout';
import { RequireAuth } from './shared/Layouts';

//shared components
const PrefetchProvider = Loadable(
  lazy(() => import('./shared/PrefetchProvider')),
);
//pages
const Home = Loadable(lazy(() => import('./pages/Home')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const Calendar = Loadable(lazy(() => import('./pages/Calendar')));
const Appointments = Loadable(lazy(() => import('./pages/Appointments')));
const DailyCheck = Loadable(lazy(() => import('./pages/DailyCheck')));
const Reviews = Loadable(lazy(() => import('./pages/Reviews')));
const Doctors = Loadable(lazy(() => import('./pages/Doctors')));
const DoctorDetails = Loadable(lazy(() => import('./pages/DoctorDetails')));

const routes: RouteObject[] = [
  {
    element: <PrefetchProvider />,
    children: [
      { element: <Home />, path: '/' },
      {
        element: <HeaderLayout />,
        children: [
          { element: <NotFound />, path: '*' },
          {
            element: <RequireAuth />,
            children: [
              { element: <Navigate to="/calendar" />, path: '/' },
              {
                element: <Calendar />,
                path: '/calendar',
              },
              {
                element: <Appointments />,
                path: '/appointments',
              },
              {
                element: <DailyCheck />,
                path: '/daily-check',
              },
              {
                element: <Reviews />,
                path: '/reviews',
              },
              {
                element: <Doctors />,
                path: '/doctors',
              },
              {
                element: <DoctorDetails />,
                path: '/doctors/:id',
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
