import { enviromentTypes } from '../models';

//server
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const IDENTITY_URL = process.env.REACT_APP_IDENTITY_URL!;
export const SIGNALR_HUB_URL = process.env.REACT_APP_SIGNALR_HUB_URL!;
export const BASE_WS_URL = process.env.REACT_APP_BASE_WS_URL;

//payments
export const KASPI_PAY_URL = process.env.REACT_APP_KASPI_URL!;
export const CLAUDPAYMENT_KEY = process.env.REACT_APP_CLAUDPAYMENT_KEY!;
export const RECEIPT_URL = process.env.REACT_APP_RECEIPT_URL!;

//firebase
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY!;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID!;
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!;
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID!;
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID!;
export const FIREBASE_VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY!;

export const isEnviromentProd = () => {
  const env = process.env.REACT_APP_ENV as enviromentTypes;
  return env === 'prod';
};
