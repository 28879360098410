import React, { FC } from 'react';
import './TextField.scss';
import useMediaQuery from '../../hooks/useMediaQuery';
import { mediaQueriesConstants } from '../../helpers/constants';
import classNames from 'classnames';

interface TextFieldProps {
  type: 'text' | 'date' | 'number' | 'phone' | 'checkbox';
  placeholder?: string;
  children?: React.ReactNode;
  error?: boolean;
  alwaysActivePlaceholder?: boolean;
  icon?: React.ReactNode;
}

const TextField: FC<TextFieldProps> = ({
  error,
  type,
  children,
  placeholder,
  alwaysActivePlaceholder = false,
  icon,
}) => {
  const isTablet = useMediaQuery(mediaQueriesConstants.minLargeTablet);
  const isMobile = useMediaQuery(mediaQueriesConstants.mobile);
  const isNotMobile = useMediaQuery(mediaQueriesConstants.minSmallTablet);

  return (
    <label
      style={error ? { borderColor: '#FD385B' } : {}}
      className={classNames('TextFieldLabel', `TextFieldLabel__${type}`, {
        TextFieldLabel__activePlaceholder: alwaysActivePlaceholder,
        TextFieldLabel__tablet: !isTablet,
        TextFieldLabel__mobile: isMobile,
      })}
    >
      {(isMobile ||
        (type === 'checkbox' && isNotMobile) ||
        alwaysActivePlaceholder) && (
        <span className={'TextFieldLabel__placeholder typography-body1'}>
          {placeholder}
        </span>
      )}

      {children}

      {icon && <span className="TextFieldLabel__icon">{icon}</span>}
    </label>
  );
};

export default TextField;
