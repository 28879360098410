import { instance } from '../index';

const serviceEndpoint = 'client/api/Device';

interface SendDeviceBody {
  userId: string;
  deviceId: string;
  deviceToken: string;
  operatingSystem: string;
  phoneVersion: string;
  appVersion: string;
  lat: string;
  lon: string;
}

export const sendDevice = async (body: SendDeviceBody) => {
  const response = await instance.post(`${serviceEndpoint}`, body);

  return response.data;
};
