import { lazy } from 'react';
import './modals.scss';
import Loadable from '../Loadable';

const Auth = Loadable(lazy(() => import('./Auth')));
const AlertModal = Loadable(lazy(() => import('./AlertModal')));
const ImageViewerModal = Loadable(lazy(() => import('./ImageViewer')));
const LogoutModal = Loadable(lazy(() => import('./LogoutModal/LogoutModal')));
const InternetConnectionModal = Loadable(
  lazy(() => import('./InternetConnectionModal')),
);
const AnalysesModal = Loadable(
  lazy(() => import('./AnalysesModal/AnalysesModal')),
);
const ConfirmationModal = Loadable(lazy(() => import('./ConfirmationModal')));
const SoonModal = Loadable(lazy(() => import('./SoonModal/SoonModal')));

export {
  Auth,
  AlertModal,
  ImageViewerModal,
  LogoutModal,
  AnalysesModal,
  InternetConnectionModal,
  ConfirmationModal,
  SoonModal,
};
