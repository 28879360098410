import {
  AlertModal,
  AnalysesModal,
  Auth,
  ImageViewerModal,
  InternetConnectionModal,
  LogoutModal,
  SoonModal,
} from 'components/Modals';
import ScrollToTopButton from 'components/ScrollToTopButton';
import Toaster from 'components/Toaster';
import useFcmToken from 'hooks/useFcmToken';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useRoutes } from 'react-router-dom';
import Tinycon from 'tinycon';
import './index.scss';
import routes from './routes';
import './theme.scss';

function App() {
  const content = useRoutes(routes);
  useFcmToken();

  useEffect(() => {
    Tinycon.setOptions({
      width: 8,
      height: 12,
      font: '18px roboto',
      color: '#12175D',
      background: 'white',
      fallback: 'force',
    });

    if (window !== undefined) {
      Notification.requestPermission();

      window.addEventListener('focus', () => {
        Tinycon.setBubble(0);
      });
    }
  }, []);

  return (
    <>
      {content}
      {createPortal(
        <>
          <Toaster />
          <Auth />
          <SoonModal />
          <AlertModal />
          <ImageViewerModal />
          <LogoutModal />
          <AnalysesModal />
          <InternetConnectionModal />
          <ScrollToTopButton />
        </>,
        document.getElementById('modal-root')!,
      )}
    </>
  );
}

export default App;
