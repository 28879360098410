import ReactDOM from 'react-dom/client';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import 'remixicon/fonts/remixicon.css';
import { WebSocketProvider } from 'shared/Layouts/WebSocket';
import App from './App';
import './locales/i18n';
import AxiosInterceptor from './shared/AxiosInterceptor/AxiosInterceptor';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <BrowserRouter>
        <AxiosInterceptor>
          <SkeletonTheme
            baseColor="#F5F6FB"
            highlightColor="#EDEFF6"
            borderRadius={0}
            duration={2}
          >
            <WebSocketProvider>
              <App />
            </WebSocketProvider>
          </SkeletonTheme>
        </AxiosInterceptor>
      </BrowserRouter>
    </RecoilRoot>
  </QueryClientProvider>,
);
