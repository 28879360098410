import { instance } from '../index';
import { IClinic, IClinicCard, IClinicCardSmalled } from '../../models/clinic';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import {
  CapitalizeKeys,
  IPagination,
  IResponseWithStatus,
  sortTypes,
} from '../../models/api';
import { getFileById } from '../file/files.api';

const serviceEndpoint = 'clinic/api/Clinics';

interface IGetClinicsParams {
  Name?: string;
  RegionCode?: string;
  CountryCode: string;
  IsActivePartner?: boolean;
  SortBy?: keyof CapitalizeKeys<IClinic>;
  SortType?: sortTypes;
  Page?: number;
  PageSize?: number;
}

interface IGetClinicsCardsParams {
  countryCode?: string;
  name?: string;
  page?: number;
  size?: number;
  sortRating?: boolean;
  isActivePartner?: boolean;
}

export interface IClinicsResponse extends IPagination {
  results: IClinic[];
}

export interface IClinicsCardsResponse extends IPagination {
  results: IClinicCardSmalled[];
}

export interface IClinicByIdResponse extends IResponseWithStatus {
  value: IClinic;
}

export interface IClinicCardResponse extends IResponseWithStatus {
  value: IClinicCard;
}

export interface IClinicByPartnerIdResponse extends IResponseWithStatus {
  value: IClinicCard[];
}

export const getClinics = async (
  params: IGetClinicsParams,
): Promise<IClinicsResponse> => {
  const searchParams = new URLSearchParams(Object.entries(params));

  const response = await instance.get(
    `${serviceEndpoint}/find?${searchParams}`,
  );
  return response.data;
};

let cancelGetClinicsCardsTokenSource: CancelTokenSource | undefined;
export const getClinicsCards = async (
  params: IGetClinicsCardsParams,
): Promise<IClinicsCardsResponse> => {
  if (cancelGetClinicsCardsTokenSource) {
    cancelGetClinicsCardsTokenSource.cancel(
      'Request canceled due to new filters',
    );
  }
  cancelGetClinicsCardsTokenSource = axios.CancelToken.source();

  const response = await instance.get(`${serviceEndpoint}/getAllClinicCard`, {
    params,
    cancelToken: cancelGetClinicsCardsTokenSource.token,
  });
  return response.data;
};

let getClinicsCardsWithImagesAxiosTokens: CancelTokenSource[] = [];
export const getClinicsCardsWithImages = async (
  params: IGetClinicsCardsParams,
): Promise<IClinicsCardsResponse> => {
  if (getClinicsCardsWithImagesAxiosTokens.length > 0) {
    for (let i = 0; i < getClinicsCardsWithImagesAxiosTokens.length; i++) {
      getClinicsCardsWithImagesAxiosTokens[i].cancel(
        'Request canceled due to new filters',
      );
    }

    getClinicsCardsWithImagesAxiosTokens = [];
  }

  const response = await getClinicsCards(params);

  const withImages = await Promise.all(
    response.results.map(async clinic => {
      if (clinic.image) {
        const getFileByIdToken = axios.CancelToken.source();
        getClinicsCardsWithImagesAxiosTokens.push(getFileByIdToken);

        let image;

        try {
          image = await getFileById(clinic.image, getFileByIdToken);
        } catch {
          image = clinic.image;
        }

        return { ...clinic, image };
      } else {
        return clinic;
      }
    }),
  );

  return { ...response, results: withImages };
};

export const getClinicCard = async (
  clinicId: string,
): Promise<IClinicCard | null> => {
  const response: AxiosResponse<IClinicCardResponse> = await instance.get(
    `${serviceEndpoint}/getCard`,
    { params: { clinicId } },
  );
  return response.data.value;
};

export const getClinicByPartnerId = async (
  partnerId: string,
): Promise<IClinicCard | null> => {
  const response: AxiosResponse<IClinicByPartnerIdResponse> =
    await instance.get(`${serviceEndpoint}/getByPartnerId`, {
      params: { partnerId },
    });

  return response.data.value[0];
};
