import notificationWebSocket from 'api/notifications/webSocket.api';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { clinicDetailsState } from 'store/clinic/clinic.states';
import { currentProfileState } from 'store/profiles/profiles.states';

export interface MessageFromNotificationWebSocket {
  target: string;
  type: number;
  arguments: any[];
}

type WebSocketContextType = {
  messageFromNotificationWebSocket: MessageFromNotificationWebSocket;
};

export const WebSocketContext = createContext<WebSocketContextType>({
  messageFromNotificationWebSocket: {} as MessageFromNotificationWebSocket,
});

type WebSocketProviderProps = {
  children: ReactNode;
};

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  children,
}) => {
  const currentProfile = useRecoilValue(currentProfileState);
  const clinicDetails = useRecoilValue(clinicDetailsState);

  const [
    messageFromNotificationWebSocket,
    setMessageFromNotificationWebSocket,
  ] = useState<MessageFromNotificationWebSocket>(
    {} as MessageFromNotificationWebSocket,
  );

  useEffect(() => {
    const handleMessage = (message: any) => {
      setMessageFromNotificationWebSocket(message);
    };

    if (currentProfile && clinicDetails?.id) {
      notificationWebSocket.connect({ clinicId: clinicDetails.id });
    }

    notificationWebSocket.addMessageHandler(handleMessage);

    return () => {
      notificationWebSocket.removeMessageHandler(handleMessage);
    };
  }, [currentProfile, clinicDetails?.id]);

  return (
    <WebSocketContext.Provider
      value={{
        messageFromNotificationWebSocket,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
