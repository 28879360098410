import { getCurrentLanguage } from 'helpers/utils';
import { atom } from 'recoil';
import { AnalysesType } from '../../components/Modals/AnalysesModal/utills';
import { ProfileRoles } from '../../models/profile';
import { languageTypes } from '../../models/settings';

export type regionModalStatuses =
  | 'selectCountry'
  | 'confirmDetected'
  | 'selectRegion';

type authModalSteps = 'closed' | 'login' | 'verification' | 'partnerLogin';
type paymentMethodsModalSteps = 'closed' | 'view' | 'add';
type applicationModal = 'closed' | 'nurse' | 'rejectedApplication' | 'created';
export type regionModalTypes =
  | { isClosable: boolean; status: regionModalStatuses; countryCode?: string }
  | false;
type withdrawModal = 'closed' | 'form' | 'confirm' | 'success' | 'fillLater';
type chooseProviderModal = null | string;

interface AlertMessageModalInterface {
  title: string;
  type: 'success' | 'error' | 'default' | 'warning';
  action?: () => void;
}

interface LogoutModalInterface {
  action: () => void;
}

interface AnalysesModalInterface {
  description: string;
  type: AnalysesType;
  removeAnalyses?: () => void;
  removeComplex?: () => void;
}

interface DeleteProfileModalInterface {
  action: () => void;
}

interface ISoonModal {
  text: string;
  subtitle: string;
}

export const authModalStepState = atom({
  key: 'authModalStep',
  default: 'closed' as authModalSteps,
});
export const LogoutModalState = atom({
  key: 'logoutModal',
  default: null as LogoutModalInterface | null,
});
export const paymentMethodsModalStepState = atom({
  key: 'paymentMethodsModalStep',
  default: 'closed' as paymentMethodsModalSteps,
});
export const applicationModalState = atom({
  key: 'applicationModal',
  default: 'closed' as applicationModal,
});
export const soonModalState = atom({
  key: 'soonModal',
  default: null as ISoonModal | null,
});
export const regionModalState = atom({
  key: 'regionModal',
  default: false as regionModalTypes,
});
export const withdrawModalState = atom({
  key: 'withdrawModal',
  default: 'closed' as withdrawModal,
});
export const analysesModalState = atom({
  key: 'analysesModal',
  default: null as AnalysesModalInterface | null,
});
export const deleteProfileModalState = atom({
  key: 'deleteProfileModal',
  default: null as DeleteProfileModalInterface | null,
});
export const referralBottomSheetState = atom({
  key: 'referralBottomSheet',
  default: false as boolean | 'opened-disableClose',
});
export const chooseProviderModalState = atom({
  key: 'chooseProviderModal',
  default: null as chooseProviderModal,
});
export const sessionRoleState = atom({
  key: 'sessionRole',
  default: null as ProfileRoles | null,
});

export const analyseFilterModalState = atom({
  key: 'analyseFilter',
  default: false,
});

export const alertMessageModal = atom({
  key: 'alertMessage',
  default: null as AlertMessageModalInterface | null,
});

export const imageViewerModal = atom({
  key: 'imageViewer',
  default: '' as string,
});

export const bonusesModalState = atom({
  key: 'bonusesModal',
  default: false as boolean,
});

export const partiallyModalState = atom({
  key: 'partiallyModal',
  default: false as boolean,
});

export const currentLanguageState = atom({
  key: 'currentLanguage',
  default: getCurrentLanguage() as languageTypes,
});
