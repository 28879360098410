import { ISpecialist } from 'models/applications';
import { EnumConsultationStatuses } from 'models/consultations';
import { languageTypes } from 'models/settings';
import { enviromentTypes } from '../models';
import { IConnectTokenRootResponse } from '../models/auth';
import { DayOfWeek, IPrice, Weekday } from '../models/clinic';
import { IAddress, ICoordinates } from '../models/geo';
import { IProfile } from '../models/profile';
import {
  LOCAL_CURRENCY,
  LS_CREDENTIALS_KEY,
  LS_USER_ADDRESS_KEY,
  consultationStatuses,
  mimeFilesTypes,
} from './constants';

//app utils
export const getLocalStorageCredentials = () => {
  try {
    return (
      (JSON.parse(
        localStorage.getItem(LS_CREDENTIALS_KEY) || 'null',
      ) as IConnectTokenRootResponse) || null
    );
  } catch {
    return null;
  }
};

export const getLocalStorageUserAddress = () => {
  try {
    return (
      (JSON.parse(
        localStorage.getItem(LS_USER_ADDRESS_KEY) || 'null',
      ) as IAddress) || null
    );
  } catch {
    return null;
  }
};

export const isEnviromentProd = () => {
  const env = process.env.REACT_APP_ENV as enviromentTypes;
  return env === 'prod';
};

export const getUserGeolocation = () => {
  return new Promise<ICoordinates<number>>((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          resolve({
            Lat: position.coords.latitude,
            Lon: position.coords.longitude,
          });
        },
        error => {
          if (error.code === 1) {
            reject(new Error('Geolocation access blocked by user'));
          } else {
            reject(new Error('Error getting geolocation: ' + error.message));
          }
        },
      );
    } else {
      console.error('Geolocation is not available');
      reject(new Error('Geolocation is not available'));
    }
  });
};

//working with files
export const createURLFromBase64 = (
  base64String: string,
  mimeType: mimeFilesTypes,
) => {
  const bytes = atob(base64String);
  const arrayBuffer = new ArrayBuffer(bytes.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < bytes.length; i++) {
    uint8Array[i] = bytes.charCodeAt(i);
  }
  const blob = new Blob([arrayBuffer], { type: mimeType });

  return URL.createObjectURL(blob);
};
export const handleDownloadFile = (file: File) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(file);
  link.setAttribute('download', file.name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const createFileFromURL = async (
  url: string,
  filename: string,
  type: mimeFilesTypes,
): Promise<File> => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], filename, { type });
};
export const createMimeType = (type: string): mimeFilesTypes => {
  switch (true) {
    case type.includes('png'):
      return 'image/png';
    case type.includes('jpg'):
      return 'image/jpg';
    case type.includes('jpeg'):
      return 'image/jpeg';
    case type.includes('docx'):
      return '.docx';
    case type.includes('pdf'):
      return 'application/pdf';
    default:
      return '.doc';
  }
};

//formatting data
export const formatNumber = (number: number, round?: boolean) => {
  if (round) {
    return number.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  } else {
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
};
export const formatNewDate = (date: Date) => {
  //returns 24-05-2002
  return `${String(date.getDate()).padStart(2, '0')}/${String(
    date.getMonth() + 1,
  ).padStart(2, '0')}/${date.getFullYear()}`;
};
export const getDatesInUTCStringFormat = (
  interval: string,
): { startDate: string; endDate: string } => {
  const today = new Date();
  const startDate = new Date();
  const endDate = new Date();

  if (interval === 'today') {
    startDate.setUTCHours(0, 0, 0, 0);
  } else {
    if (interval.includes('day')) {
      const daysAgo = parseInt(interval);
      startDate.setUTCDate(today.getUTCDate() - daysAgo);
    } else if (interval.includes('week')) {
      const weeksAgo = parseInt(interval);
      startDate.setUTCDate(today.getUTCDate() - weeksAgo * 7);
    } else if (interval.includes('month')) {
      startDate.setUTCDate(1);
    } else if (interval.includes('all')) {
      startDate.setUTCDate(1);
      startDate.setUTCMonth(8);
      startDate.setUTCFullYear(2022);
    }
    startDate.setUTCHours(0, 0, 0, 0);
  }
  endDate.setUTCHours(23, 59, 59, 999);

  return { startDate: startDate.toISOString(), endDate: endDate.toISOString() };
};
export const formatApiComingDate = (timestamp: string) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${day.toString().padStart(2, '0')}.${month
    .toString()
    .padStart(2, '0')}.${year}`;
};
export const formatApiComingTime = (timestamp: string) => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return (
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0')
  );
};
export const firstNameLastNameHandler = (
  firstName: string | undefined | null,
  lastName: string | undefined | null,
  phoneNumber?: string | undefined | null,
  midName?: string | undefined | null,
) => {
  let fio = '';

  if (lastName) fio += lastName;
  if (firstName) fio += fio ? ` ${firstName}` : firstName;
  if (midName) fio += fio ? ` ${midName}` : midName;

  if (fio) return fio;
  else if (phoneNumber) return phoneNumber;
  else return '--- --- ---';
};
export const formatPhoneNumberForReading = (
  phoneNumber: string,
  countryCode: string,
) => {
  let formattedNumber: string;

  const digitsOnly = phoneNumber?.replace(/\D/g, '');

  if (countryCode?.toLowerCase()?.includes('kz')) {
    const countryPrefix = '+7';
    const areaCode = digitsOnly.substr(1, 3);
    const firstPart = digitsOnly.substr(4, 3);
    const secondPart = digitsOnly.substr(7, 2);
    const thirdPart = digitsOnly.substr(9, 2);
    formattedNumber = `${countryPrefix} ${areaCode} ${firstPart}-${secondPart}-${thirdPart}`;
  } else if (countryCode?.toLowerCase()?.includes('kg')) {
    const countryPrefix = '+996';
    const areaCode = digitsOnly.substr(3, 3);
    const firstPart = digitsOnly.substr(6, 2);
    const secondPart = digitsOnly.substr(8, 2);
    const thirdPart = digitsOnly.substr(10, 2);
    formattedNumber = `${countryPrefix} ${areaCode} ${firstPart}-${secondPart}-${thirdPart}`;
  } else {
    formattedNumber = phoneNumber;
  }

  return formattedNumber;
};
export const cutStringForReading = (string: string, max: number) => {
  if (string.length > max) {
    const words = string.split(' ');
    let displayString = '';
    let lengthCount = 0;

    for (const word of words) {
      if (lengthCount + word.length <= max) {
        displayString += word + ' ';
        lengthCount += word.length + 1;
      } else {
        displayString += '...';
        break;
      }
    }

    return displayString.trim();
  }

  return string;
};
export const handleCardNumberChange = (value: string) => {
  // Remove non-digit characters from the input
  const cardNumberOnlyDigits = value.replace(/\D/g, '');

  // Format the card number with spaces
  let formattedCardNumber = '';
  for (let i = 0; i < cardNumberOnlyDigits.length; i += 4) {
    const chunk = cardNumberOnlyDigits.slice(i, i + 4);
    formattedCardNumber += chunk + ' ';
  }

  return formattedCardNumber.trim();
};

//creating a new data
export const createTotalByBonuses = (bonuses: number, total: number) => {
  if (bonuses >= total) {
    return 0;
  } else {
    return total - bonuses;
  }
};

export const formatCustomDate = (dateString: string) => {
  const currentDate = new Date();
  const date = new Date(dateString);

  // Извлекаем день, месяц и год из объекта Date
  const day = date.getDate();
  const month = date.getMonth() + 1; // Месяцы в объекте Date начинаются с 0
  const year = date.getFullYear();

  // Извлекаем часы и минуты из объекта Date
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Форматируем день, месяц, часы и минуты с ведущими нулями
  const formattedDay = day.toString().padStart(2, '0');
  const formattedMonth = month.toString().padStart(2, '0');
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  if (currentDate.toDateString() === date.toDateString()) {
    // Если дата совпадает с текущей датой, показываем только время
    return `Сегодня, ${formattedHours}:${formattedMinutes}`;
  } else if (currentDate.getDate() - date.getDate() === -1) {
    // Если дата отличается от текущей на 1 день, показываем "Завтра, часы:минуты"
    return `Завтра, ${formattedHours}:${formattedMinutes}`;
  } else if (currentDate.getDate() - date.getDate() === -2) {
    // Если дата отличается от текущей на 2 день, показываем "Послезавтра, часы:минуты"
    return `Завтра, ${formattedHours}:${formattedMinutes}`;
  } else if (currentDate.getDate() - date.getDate() === 1) {
    // Если дата отличается от текущей на 1 день, показываем "Вчера, часы:минуты"
    return `Вчера, ${formattedHours}:${formattedMinutes}`;
  } else if (currentDate.getDate() - date.getDate() === 2) {
    // Если дата отличается от текущей на 2 дня, показываем "Позавчера, часы:минуты"
    return `Позавчера, ${formattedHours}:${formattedMinutes}`;
  } else {
    // В остальных случаях показываем полную дату и время "дд.мм.гггг, часы:минуты"
    return `${formattedDay}.${formattedMonth}.${year}, ${formattedHours}:${formattedMinutes}`;
  }
};

export const getActualCurrency = () => {
  return localStorage.getItem(LOCAL_CURRENCY)
    ? localStorage.getItem(LOCAL_CURRENCY)
    : '₸';
};

export const getCurrencyByCountryCode = (countryCode: string | undefined) => {
  switch (countryCode) {
    case 'KG':
      return 'с';
    case 'KZ':
      return '₸';
    default:
      return '₸';
  }
};

export const extractDomain = () => {
  const url = window.location.hostname;
  const domainParts = url.split('.');
  if (domainParts.length > 1) return domainParts[domainParts.length - 1];

  return '';
};

export const anchorLink = (hash: string, behavior: ScrollBehavior) => {
  if (hash) {
    const target: HTMLElement | null = document.getElementById(hash);

    if (target) {
      const { top: nodeTop, height: nodeHeight } =
        target.getBoundingClientRect();

      const offsetTop = nodeTop > 0 ? nodeTop : window.pageYOffset + nodeTop;

      window.scrollTo({ top: offsetTop - nodeHeight / 5, behavior });
    }
  }
};

export const getPaymentCurrency = () => {
  switch (localStorage.getItem('localCurrency')) {
    case 'С':
      return 'kgs';
    case '₸':
      return 'kzt';
    default:
      return 'kzt';
  }
};

export const isForExit = (arr: IPrice[]) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i]?.notAnExit) {
      return false;
    }
  }
  return true;
};

export const capitalizeFirstLetter = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const formatAmount = (number: number): number => {
  return parseFloat(number.toFixed(2));
};

export const truncateText = (text: string, limit: number = 100) => {
  if (text.length <= limit) {
    return text;
  }
  return `${text.slice(0, limit)}...`;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  let formattedNumber = '';

  if (phoneNumber) {
    const digitsOnly = phoneNumber.replace(/\D/g, '');

    if (phoneNumber.startsWith('7')) {
      const countryPrefix = '+7';
      const areaCode = digitsOnly.slice(1, 4);
      const firstPart = digitsOnly.slice(4, 7);
      const secondPart = digitsOnly.slice(7, 9);
      const thirdPart = digitsOnly.slice(9, 11);
      formattedNumber = `${countryPrefix} ${areaCode} ${firstPart} ${secondPart} ${thirdPart}`;
    } else if (phoneNumber.startsWith('996')) {
      const countryPrefix = '+996';
      const areaCode = digitsOnly.slice(3, 6);
      const firstPart = digitsOnly.slice(6, 8);
      const secondPart = digitsOnly.slice(8, 10);
      const thirdPart = digitsOnly.slice(10, 12);
      formattedNumber = `${countryPrefix} ${areaCode} ${firstPart} ${secondPart} ${thirdPart}`;
    } else {
      formattedNumber = phoneNumber;
    }
  }

  return formattedNumber;
};

export const getPatientPhoneNumber = (patient: IProfile | null) => {
  if (!patient) {
    return '';
  }

  const phoneNumber = formatPhoneNumber(patient.phoneNumber);

  return `${phoneNumber}${patient.isVirtualUser ? ` (Владелец группы)` : ''}`;
};

export const isTodayDate = (date: Date | string): boolean => {
  const inputDate = typeof date === 'string' ? new Date(date) : date;
  const today = new Date();

  return (
    inputDate.getDate() === today.getDate() &&
    inputDate.getMonth() === today.getMonth() &&
    inputDate.getFullYear() === today.getFullYear()
  );
};

export const getSortedTimeArray = (timeList: string[]) => {
  return timeList.sort((a, b) => {
    const time1 = new Date(`2000-01-01T${a}`);
    const time2 = new Date(`2000-01-01T${b}`);
    return time1.valueOf() - time2.valueOf();
  });
};

export function getDayOfWeek(date: Date): DayOfWeek {
  const weekday = new Date(date).toLocaleDateString('en-EN', {
    weekday: 'long',
  });

  const numberOfWeekByName = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  return numberOfWeekByName[weekday as Weekday] as DayOfWeek;
}

export function getUnavailableIntervals(
  timeValues: string[],
  bookedAppointments: string[],
): {
  start: string;
  end: string;
}[] {
  const unavailableIntervals: { start: string; end: string }[] = [];
  let startInterval: string | null = null;

  for (const time of timeValues) {
    if (!bookedAppointments.includes(time)) {
      // If the time is not booked, start a new interval or extend the current one
      if (startInterval === null) {
        startInterval = time;
      }
    } else {
      // If the time is booked, end the current interval
      if (startInterval !== null) {
        unavailableIntervals.push({ start: startInterval, end: time });
        startInterval = null;
      }
    }
  }

  // Filter out intervals before the first appointment and after the last appointment
  unavailableIntervals.forEach((interval, index) => {
    if (
      interval.end <= bookedAppointments[0] ||
      interval.start >= bookedAppointments[bookedAppointments.length - 1]
    ) {
      unavailableIntervals.splice(index, 1);
    }
  });

  return unavailableIntervals;
}

export const toDate = (date: string, showTime?: boolean, timezone?: string) => {
  if (Date.parse(date)) {
    if (showTime) {
      if (timezone) {
        return new Intl.DateTimeFormat('ru-Ru', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          timeZone: timezone,
        }).format(new Date(date));
      } else {
        return new Intl.DateTimeFormat('ru-Ru', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }).format(new Date(date));
      }
    } else {
      return new Intl.DateTimeFormat('ru-Ru', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(new Date(date));
    }
  }
};

export function generateTimeArray(interval: string): string[] {
  const startTime = '00:00:00';
  const totalMinutesInDay = 24 * 60;

  const intervalMinutes =
    Number(interval.split(':')[0]) * 60 + Number(interval.split(':')[1]);
  const numberOfIntervals = Math.floor(totalMinutesInDay / intervalMinutes);

  const timeArray: string[] = [];
  let currentTime = startTime;

  for (let i = 0; i < numberOfIntervals; i++) {
    timeArray.push(currentTime);
    const [hours, minutes, seconds] = currentTime.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes + intervalMinutes;
    currentTime = `${Math.floor(totalMinutes / 60)
      .toString()
      .padStart(2, '0')}:${(totalMinutes % 60)
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  return timeArray;
}

export function getFormattedOffset(
  inputDate: Date,
  { withSeconds = false, withSign = true, needPlus = true } = {},
): string {
  const offsetMinutes: number = inputDate.getTimezoneOffset();
  const sign: string = offsetMinutes > 0 ? '-' : needPlus ? '+' : '';
  const hours: number = Math.floor(Math.abs(offsetMinutes) / 60);
  const minutes: number = Math.abs(offsetMinutes) % 60;

  const formattedOffset: string = `${withSign ? sign : ''}${hours
    .toString()
    .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}${
    withSeconds ? ':00' : ''
  }`;

  return formattedOffset;
}

export const getDateTimeOffset = (date: Date): string => {
  const inputDate = new Date(date);

  const year = inputDate.getFullYear();
  const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
  const day = inputDate.getDate().toString().padStart(2, '0');
  const hours = inputDate.getHours().toString().padStart(2, '0');
  const minutes = inputDate.getMinutes().toString().padStart(2, '0');
  const seconds = inputDate.getSeconds().toString().padStart(2, '0');
  const offsetHours = getFormattedOffset(inputDate);

  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000${offsetHours}`;

  return formattedDateTime;
};

export function sumTimespans(time1: string, time2: string): string {
  const parseTime = (time: string): number => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const formatTime = (seconds: number): string => {
    const pad = (num: number): string => (num < 10 ? '0' : '') + num;
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  };

  const totalSeconds = parseTime(time1) + parseTime(time2);
  const result = formatTime(totalSeconds);

  return result;
}

export const getSpecializationBySpec = (doctor?: ISpecialist) => {
  const specialization = doctor?.specialization?.name || '';
  const specializations =
    doctor?.specializations?.map(spec => spec.name).join(', ') || '';

  return specialization || specializations;
};

export function formatToRussianYears(num?: number): string {
  if (!num) {
    return '';
  }

  if (num === 0) {
    return '0 лет';
  } else if (num === 1) {
    return '1 год';
  } else if (num % 10 === 1 && num % 100 !== 11) {
    return `${num} год`;
  } else if (
    num % 10 >= 2 &&
    num % 10 <= 4 &&
    (num % 100 < 10 || num % 100 >= 20)
  ) {
    return `${num} года`;
  } else {
    return `${num} лет`;
  }
}

export const getCurrentLanguage = (): languageTypes => {
  const lang = (localStorage.getItem('language') as languageTypes) || null;
  return lang || 'ru';
};

export const getConsultationStatus = (value: EnumConsultationStatuses) =>
  consultationStatuses.find(status => status.value === value)?.name ||
  'statuses.notFound';

export const getInfiniteNextPageParam = ({
  pageCount,
  currentPage,
}: {
  pageCount: number;
  currentPage: number;
}) => {
  const nextPage = currentPage + 1;

  return nextPage <= pageCount ? nextPage : null;
};

export const getProfileFullName = (profile: IProfile | null) => {
  if (!profile) {
    return 'Удаленный профиль';
  }

  return `${profile?.lastName ?? 'Имя'} ${profile?.firstName ?? 'Фамилия'} ${
    profile?.midName ?? 'Отчество'
  }`;
};

export function resetScroll() {
  window.scrollTo(0, 0);
}

export function isDateBetween({
  checkDate,
  beginDate,
  endDate,
}: {
  checkDate: Date;
  beginDate: Date;
  endDate: Date;
}) {
  return checkDate >= beginDate && checkDate <= endDate;
}
