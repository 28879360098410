import React, { FormEvent, useState } from 'react';
import './auth.scss';
import Logo from '../../../assets/brand/logo.png';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Button from '../../Button';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  alertMessageModal,
  authModalStepState,
} from '../../../store/modals/modals.states';
import {
  LS_CREDENTIALS_KEY,
  mediaQueriesConstants,
  partnerConnectTokenConstants,
} from '../../../helpers/constants';
import { Trans, useTranslation } from 'react-i18next';
import TextField from 'components/TextField/TextField';
import { postConnectToken } from 'api/auth/auth.api';
import { getCurrentProfile } from 'api/client/profile.api';
import { credentialsState } from 'store/auth/auth.states';
import {
  currentProfileAvatarState,
  currentProfileState,
} from 'store/profiles/profiles.states';
import { getFileById } from 'api/file/files.api';
import { clinicDetailsState } from 'store/clinic/clinic.states';
import { getClinicByPartnerId } from 'api/clinic/clinics.api';
import { IClinicCard } from 'models/clinic';
import { AxiosError } from 'axios';

const PartnerLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [authData, setAuthData] = useState<{ login: string; password: string }>(
    {
      login: '',
      password: '',
    },
  );
  const isDesktop = useMediaQuery(mediaQueriesConstants.minDesktop);
  const isMediumTablet = useMediaQuery(mediaQueriesConstants.minMediumTablet);
  const isLargeTablet = useMediaQuery(mediaQueriesConstants.minLargeTablet);

  const [loading, setLoading] = useState<boolean>(false);

  const setCredentials = useSetRecoilState(credentialsState);
  const setCurrentProfile = useSetRecoilState(currentProfileState);
  const setAuthModalStep = useSetRecoilState(authModalStepState);
  const setCurrentProfileAvatar = useSetRecoilState(currentProfileAvatarState);
  const setClinicDetails = useSetRecoilState(clinicDetailsState);
  const setAlertModal = useSetRecoilState(alertMessageModal);

  const handleSubmit = async (e?: FormEvent) => {
    if (e) e.preventDefault();
    setLoading(true);
    try {
      const requestData = new URLSearchParams();
      requestData.append('client_id', partnerConnectTokenConstants.client_id);
      requestData.append(
        'client_secret',
        partnerConnectTokenConstants.client_secret,
      );
      requestData.append('grant_type', partnerConnectTokenConstants.grant_type);
      requestData.append('username', authData.login);
      requestData.append('password', authData.password);

      const credentials = await postConnectToken(requestData);
      localStorage.setItem(LS_CREDENTIALS_KEY, JSON.stringify(credentials));

      const profileData = await getCurrentProfile();

      const promises = [
        getClinicByPartnerId(profileData.id).then(
          (clinic: IClinicCard | null) => {
            setClinicDetails(clinic);

            if (clinic?.image) {
              promises.push(
                getFileById(clinic.image).then(setCurrentProfileAvatar),
              );
            }
          },
        ),
      ];

      await Promise.allSettled(promises);

      setCredentials(credentials);
      setCurrentProfile(profileData);

      setAuthModalStep('closed');
      navigate('/calendar');
    } catch (error) {
      if ((error as AxiosError).response?.status === 500) {
        setAlertModal({
          title: 'Ошибка сервера. Попробуйте еще раз',
          type: 'error',
        });
      } else {
        setAlertModal({
          title: 'Неправильный логин или пароль',
          type: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 13 || e.which === 13) {
      handleSubmit();
    }
  };

  const handleNavigate = (link: string) => {
    setAuthModalStep('closed');
    navigate(link);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: 'login' | 'password',
  ) => {
    setAuthData(authData => ({
      ...authData,
      [key]: e.target.value,
    }));
  };

  return (
    <section onKeyDown={handleKeyDown}>
      {(isDesktop || (isMediumTablet && !isLargeTablet)) && (
        <img className="authModal__logo" src={Logo} alt="logo" />
      )}

      <h2 className="modal__title typography-h2">
        <Trans i18nKey={`auth.welcomeToQomek`} components={{ br: <br /> }} />
      </h2>

      <form onSubmit={handleSubmit}>
        <div className="authModal-partner__fields">
          <TextField type="text">
            <input
              type="text"
              placeholder={t('placeholders.login')!}
              value={authData.login}
              onChange={e => handleChange(e, 'login')}
            />
          </TextField>
          <TextField type="text">
            <input
              type="password"
              placeholder={t('placeholders.password')!}
              value={authData.password}
              onChange={e => handleChange(e, 'password')}
            />
          </TextField>
        </div>
        <Button
          id="loginButton"
          type="submit"
          clx={'authModal__loginAsPartner'}
          text={t('actions.login')}
          fullWidth
          size={'large'}
          loading={loading}
        />
      </form>
      <p className="typography-body2 authModal__agreement">
        <Trans
          i18nKey={`auth.continuingAgreement`}
          components={{
            span: (
              <span onClick={() => handleNavigate(`/${'kz'}/privacy-policy`)} />
            ),
            span2: (
              <span onClick={() => handleNavigate(`/${'kz'}/online-payment`)} />
            ),
          }}
        />
      </p>
    </section>
  );
};

export default PartnerLogin;
