import { atom } from 'recoil';
import { IProfile } from '../../models/profile';
import { IUserScope } from '../../models/clinic';

export const currentProfileState = atom({
  key: 'currentProfile',
  default: null as IProfile | null,
});

export const currentProfileAvatarState = atom({
  key: 'currentProfileAvatar',
  default: '' as string,
});

export const currentProfileGroupState = atom({
  key: 'currentProfileGroup',
  default: [] as IProfile[],
});

export const currentProfileScopesState = atom({
  key: 'currentProfileScope',
  default: [] as IUserScope[],
});

export const cachedProfilesState = atom({
  key: 'cachedProfiles',
  default: [] as IProfile[],
});
