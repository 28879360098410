import { ReactElement, useEffect } from 'react';
import useLogout from '../../hooks/useLogout';
import { instance } from '../../api';
import { AxiosError } from 'axios';

type InterceptorProps<T extends ReactElement> = {
  children: T;
};

const AxiosInterceptor = <T extends ReactElement>({
  children,
}: InterceptorProps<T>) => {
  const logout = useLogout();

  useEffect(() => {
    const interceptor = instance.interceptors.response.use(
      response => response,
      (error: AxiosError) => {
        if (error?.response?.status === 401) {
          logout();
        }

        return Promise.reject(error);
      },
    );

    return () => instance.interceptors.response.eject(interceptor);
  }, []);

  return children;
};

export default AxiosInterceptor;
