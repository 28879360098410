import { BASE_WS_URL } from 'helpers/enviroment';

type MessageHandler = (data: any) => void;

interface WebSocketEvent {
  wasClean: boolean;
  code: number;
  reason: string;
}

interface ParsedData {
  type: number;
  error?: string;
}

class NotificationWebSocket {
  private socket: WebSocket | null = null;
  private messageHandlers: MessageHandler[] = [];
  private pingInterval: number | null = null;
  private clinicId: string | null = null;

  connect({ clinicId }: { clinicId: string }): void {
    console.log('Попытка установить соединение WebSocket...');
    this.clinicId = clinicId;
    this.socket = new WebSocket(`${BASE_WS_URL}/notification/rtc`);
    this.socket.onopen = this.onOpen.bind(this);
    this.socket.onmessage = this.onMessage.bind(this);
    this.socket.onerror = this.onError.bind(this);
    this.socket.onclose = this.onClose.bind(this);
  }

  private onOpen(event: Event): void {
    console.log('Соединение WebSocket установлено', event);
    const initialMessage =
      JSON.stringify({
        protocol: 'json',
        version: 1,
      }) + '\u001e';
    this.sendMessage(initialMessage);

    setTimeout(() => {
      const message =
        JSON.stringify({
          type: 1,
          target: 'Autojoin',
          arguments: [
            '6cbe492c-eb83-4d03-9e4f-5b0fa8618dca', // user id
            '2i1oiewo', // device token
            '3', // notification type 3 = offline consultation
            this.clinicId, // room - clinic id
          ],
        }) + '\u001e';
      this.sendMessage(message);
    }, 1000);

    this.pingInterval = window.setInterval(() => {
      if (this.socket?.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'ping', RS: '\u001e' }));
      }
    }, 30000);
  }

  private onMessage(event: MessageEvent): void {
    try {
      const parsedData: ParsedData = JSON.parse(
        event.data.replace(/[\x1E]/g, '').trim(),
      );
      if (parsedData.type === 6) {
      } else if (parsedData.error) {
        console.error('Ошибка WebSocket:', parsedData.error);
      } else {
        this.messageHandlers.forEach(handler => handler(parsedData));
      }
    } catch (error) {
      console.error('Ошибка разбора JSON:', error);
    }
  }

  private onError(error: Event): void {
    console.error('Ошибка WebSocket:', error);
  }

  private onClose(event: CloseEvent): void {
    console.log('Соединение WebSocket закрыто', event);
    console.log(
      `Код закрытия: ${event.code}, Причина закрытия: ${event.reason}`,
    );

    if (event.wasClean) {
      console.log('Соединение закрыто чисто.');
    } else {
      console.log('Соединение закрыто с ошибкой.');
    }

    if (this.pingInterval !== null) {
      clearInterval(this.pingInterval);
    }

    if (event.code !== 1000) {
      setTimeout(() => {
        console.log('Повторное подключение...');
        if (this.clinicId) {
          this.connect({ clinicId: this.clinicId });
        }
      }, 5000);
    }
  }

  close(): void {
    if (this.socket) {
      if (this.pingInterval !== null) {
        clearInterval(this.pingInterval);
      }
      this.socket.close(1000, 'Клиент закрыл соединение');
      console.log('Соединение WebSocket закрыто клиентом');
    }
  }

  sendMessage(message: string): void {
    if (this.socket?.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.error('Соединение WebSocket не открыто');
    }
  }

  addMessageHandler(handler: MessageHandler): void {
    this.messageHandlers.push(handler);
  }

  removeMessageHandler(handler: MessageHandler): void {
    this.messageHandlers = this.messageHandlers.filter(h => h !== handler);
  }
}

const notificationWebSocket = new NotificationWebSocket();
export default notificationWebSocket;
