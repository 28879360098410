import React, { useEffect } from 'react';
import './mobileAuth.scss';
import { useRecoilState } from 'recoil';
import { authModalStepState } from '../../store/modals/modals.states';
import PartnerLogin from 'components/Modals/Auth/PartnerLogin';

const MobileAuth = () => {
  const [authModalStep, seAuthModalStep] = useRecoilState(authModalStepState);

  const handleAuthStep = () => {
    switch (authModalStep) {
      case 'login':
        return <PartnerLogin />;
      default:
        return null;
    }
  };

  useEffect(() => {
    return () => seAuthModalStep('closed');
  }, []);

  return (
    <div className="container">
      <div className="mobileAuth">{handleAuthStep()}</div>
    </div>
  );
};

export default MobileAuth;
